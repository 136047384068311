import React from 'react';
import AboutUs from '../../components/AboutUs/AboutUs';
const AboutUsPAge = () => {
  return (
    <div>
      <AboutUs />
    </div>
  );
};

export default AboutUsPAge;
